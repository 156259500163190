<template>
  <app-modal
    v-if="modal"
    :title="$t('modal.journalsselection.title')"
    modal-size="modal-lg"
    @close="close(true)"
  >
    <template slot="body">
      <div class="row">
        <div class="col-lg-4">
          <app-input
            ref="searchInput"
            id="journals_search_diaries_input"
            :label="$t('modal.journalsselection.search_label')"
            :placeholder="$t('modal.journalsselection.search_placeholder')"
            :magnifier="true"
            @input="handleFindDiaries"
          />
        </div>
        <div class="col-lg-8">
          <div class="journals-modal__btns-wrapper">
            <button class="journals-modal__btn-primary" @click="handleSelectAll">{{ $t('buttons.choose_all') }}</button>
            <button class="journals-modal__btn-primary" @click="handleSelectDisplayed">{{ $t('buttons.choose_shown') }}</button>
            <button class="journals-modal__btn-primary" @click="handleDeselectAll(true)">{{ $t('buttons.choose_cancel') }}</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="journals-modal__box">
            <div class="journals-modal__box-label">{{ $t('modal.journalsselection.list_title') }}</div>
            <div class="journals-modal__box-inner">
              <div class="journals-modal__box-diaries">
                <div
                  v-for="(site, index) in ungroupedSites"
                  :key="`ungrouped-site-${index}`"
                  class="journals-modal__select-item-checkbox"
                >
                  <app-checkbox
                    v-model="site.selected"
                    :id="`journals-diaries-item-ungrouped-${index}`"
                    :label="site.title"
                    customClass="journals-modal__checkbox-item journals-modal__checkbox-item--lead"
                  />
                </div>
                <div
                  v-for="region in groupedRegions"
                  :key="region.id"
                  class="journals-modal__checkbox-group"
                >
                  <app-checkbox
                    v-model="regionSelection[region.id]"
                    :label="region.name"
                    @input="handleRegionSelection(region.id, region.sites)"
                    :id="`journals-diaries-item-region-${region.id}`"
                    customClass="journals-modal__checkbox-item journals-modal__checkbox-item--lead"
                  />
                  <div class="journals-modal__checkbox-group--sub">
                    <div
                      v-for="(site, index) in region.sites"
                      :key="`item-${index}`"
                      class="journals-modal__select-item-checkbox"
                    >
                      <app-checkbox
                        v-model="site.selected"
                        :id="`journals-diaries-item-${region.id}-${index}`"
                        :label="site.title"
                        customClass="journals-modal__checkbox-item"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="journals-modal__box">
            <div class="journals-modal__box-label">{{ $t('modal.journalsselection.choosen_title') }}</div>
            <div class="journals-modal__box-inner">
              <div class="journals-modal__selected-btns">
                <button
                  class="journals-modal__selected-btn"
                  v-for="(site, index) in selectedSites"
                  :key="`selected-${index}`"
                  @click="handleRemoveSelectedSite(site.id)"
                >
                  {{ site.title }}
                  <span class="journals-modal__selected-btn-remove">
                    <i class="fas fa-times"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <button
        type="button"
        class="journals-modal__btn-secondary"
        data-test="journals_modal_cancel"
        @click="close(true)"
      >
        {{ $t('buttons.cancel') }}
      </button>
      <button
        type="button"
        class="journals-modal__btn-primary"
        data-test="journals_modal_confirm"
        @click="handleJournalsModalConfirm"
      >
        {{ $t('buttons.insert_selection') }}
      </button>
    </template>
  </app-modal>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import Modal from '@/components/shared/Modal'
import Checkbox from '@/components/form/Checkbox'

export default {
  name: 'JournalsSelectionModal',
  props: {
    defaultSelectedSiteIds: {
      type: Array,
      default: () => []
    },
    permissionModule: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      modal: false,
      enabledSitesData: [],
      regions: [],
      regionSelection: {}
    }
  },
  components: {
    appModal: Modal,
    appInput: Input,
    appCheckbox: Checkbox
  },
  watch: {
    enabledSitesData: {
      deep: true,
      handler () {
        this.updateRegionSelection()
      }
    }
  },
  computed: {
    sitesGroupedByRegion () {
      const regionsWithSites = this.regions
        .map(region => {
          const shownSites = this.enabledSitesData.filter(
            site => site.regionId === region.id && site.show
          )
          if (shownSites.length > 0) {
            return {
              ...region,
              sites: shownSites
            }
          }
          return null
        })
        .filter(region => region !== null)

      const ungroupedSites = this.enabledSitesData.filter(
        site => !site.regionId && site.show
      )
      const ungroupedRegion = {
        id: 'ungrouped',
        name: '', // no label for 'ungrouped' sites
        sites: ungroupedSites
      }
      return [ungroupedRegion, ...regionsWithSites].filter(group => group.sites.length > 0)
    },
    enabledSites () {
      const sites = this.$store.getters['site/enabledSites'](this.permissionModule)

      return sites.map(site => {
        if (site.selected === undefined) {
          this.$set(site, 'selected', false)
          this.$set(site, 'show', true)
        }
        return site
      })
    },
    selectedSites () {
      return this.enabledSitesData.filter(site => site.selected)
    },
    enabledShownSites () {
      return this.enabledSitesData.filter(site => site.show)
    },
    ungroupedSites () {
      return this.sitesGroupedByRegion.find(region => region.id === 'ungrouped')?.sites || []
    },
    groupedRegions () {
      return this.sitesGroupedByRegion.filter(region => region.id !== 'ungrouped')
    }
  },
  methods: {
    handleSelectAll () {
      this.enabledSitesData.forEach(site => {
        this.$set(site, 'selected', true)
      })
    },
    handleDeselectAll (clearSearchInput) {
      this.enabledSitesData.forEach(site => {
        this.$set(site, 'selected', false)
      })
      if (clearSearchInput) {
        this.clearSearchInput()
      }
    },
    handleSelectDisplayed () {
      this.enabledSitesData.forEach(site => {
        if (site.show) {
          this.$set(site, 'selected', true)
        }
      })
    },
    handleRegionSelection (regionId, sites) {
      const isSelected = this.regionSelection[regionId]
      sites.forEach(site => {
        this.$set(site, 'selected', isSelected)
      })
    },
    updateRegionSelection () {
      this.regions.forEach(region => {
        const regionSites = this.enabledSitesData.filter(site => site.regionId === region.id)
        this.$set(this.regionSelection, region.id, regionSites.every(site => site.selected))
      })
    },
    handleRemoveSelectedSite (id) {
      const indexOfSelectedSite = this.enabledSitesData.findIndex(site => site.id === id)
      if (indexOfSelectedSite !== -1) {
        this.$set(this.enabledSitesData[indexOfSelectedSite], 'selected', false)
      }
    },
    handleJournalsModalConfirm () {
      this.$emit('onSelectedSites', this.selectedSites)
      this.close()
    },
    setDefaultSiteIds () {
      this.handleDeselectAll()
      if (this.defaultSelectedSiteIds.length === 0) {
        return
      }
      this.defaultSelectedSiteIds.forEach(siteId => {
        const site = this.enabledSitesData.find(site => site.id === siteId)
        if (site) {
          this.$set(site, 'selected', true)
        }
      })
    },
    normalizedSearchTerm (searchTerm) {
      return searchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
    },
    handleFindDiaries (searchTerm) {
      const normalizedSearchTerm = this.normalizedSearchTerm(searchTerm)
      this.enabledSitesData.forEach((site, index) => {
        if (searchTerm.length < 2) {
          this.$set(site, 'show', true)
        } else {
          const normalizedTitle = this.normalizedSearchTerm(site.title)
          const isShown = normalizedTitle.includes(normalizedSearchTerm)
          this.$set(this.enabledSitesData[index], 'show', isShown)
        }
      })
    },
    clearSearchInput () {
      this.$refs.searchInput.$refs.journals_search_diaries_input.value = ''
      this.handleFindDiaries('')
    },
    open () {
      this.modal = true
      this.setDefaultSiteIds()
    },
    close (setDefaultSiteIds) {
      this.modal = false
      if (setDefaultSiteIds) {
        this.setDefaultSiteIds()
      }
      this.clearSearchInput()
    },
    getRegions () {
      this.$store.dispatch('site/fetchRegions')
        .then(() => {
          this.regions = this.$store.getters['site/regions']
          this.regions.forEach(region => {
            this.$set(this.regionSelection, region.id, false)
          })
        })
    }
  },
  created () {
    this.enabledSitesData = this.enabledSites
    this.getRegions()
    this.setDefaultSiteIds()
  }
}
</script>
<style scoped lang="scss">
.journals-modal {
  &__box {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
  }
  &__box-label {
    font-family: "Roboto", sans-serif;
    font-size: rem(14px);
    font-weight: 400;
    color: #8A96AC;
  }
  &__box-inner {
    padding: rem(7px);
    border-radius: rem(6px);
    border: solid 1px #d1dbe4;
  }
  &__box-diaries {
    max-height: rem(500px);
    overflow-y: scroll;
  }
  &__btns-wrapper {
    display: flex;
    gap: rem(5px);
    margin-top: rem(24px);
  }
  &__btn-primary {
    padding-left: rem(25px);
    padding-right: rem(25px);
    font-family: "Roboto", sans-serif;
    font-size: rem(13px);
    font-weight: 500;
    color: #fff;
    background: #6599FE;
    border: none;
    border-radius: rem(6px);
    cursor: pointer;
    height: rem(40px);
    transition: background 100ms;
    display: flex;
    align-items: center;
    &:hover {
      background: darken(#6599FE, 10%);
    }
  }
  &__btn-secondary {
    padding-left: rem(25px);
    padding-right: rem(25px);
    font-family: "Roboto", sans-serif;
    font-size: rem(13px);
    font-weight: 500;
    color: #465674;
    background: #fff;
    border-radius: rem(6px);
    cursor: pointer;
    height: rem(40px);
    transition: all 100ms;
    display: flex;
    align-items: center;
    border: solid 1px #d1dbe4;
    &:hover {
      color: #6599fe;
      border: solid 1px #6599fe;
    }
  }
  &__selected-btns {
    display: flex;
    gap: rem(5px);
    flex-wrap: wrap;
  }
  &__selected-btn {
    padding-left: rem(7px);
    padding-right: rem(7px);
    font-family: "Roboto", sans-serif;
    font-size: rem(13px);
    color: #fff;
    background: #6599FE;
    border-radius: rem(6px);
    cursor: pointer;
    transition: all 100ms;
    display: flex;
    align-items: center;
    border: 0;
    &:hover {
      background: darken(#6599FE, 10%);
    }
  }
  &__selected-btn-remove {
    padding-left: rem(7px);
  }
  &__checkbox-group--sub &__select-item-checkbox {
    ::v-deep .custom-checkbox {
      padding-left: rem(40px);
    }
  }
  &__checkbox-item {
    &--lead ::v-deep .custom-checkbox {
      font-weight: 600;
    }
  }
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
